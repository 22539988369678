import {useEffect, useState} from "react";
import {getHistory} from "../api";
import {useLocation, useParams} from "react-router-dom";

export default function () {
  const location = useLocation();
  const [article, setArticle] = useState([]);
  const { id } = useParams();

  const searchParams = new URLSearchParams(location.search);
  const hash = searchParams.get('hash');

  useEffect(() => {
    getHistory(id, hash).then(res => {
      setArticle(res)
    })
  }, [])

  return <div className={'row'} style={{ minHeight: `calc(100vh - 200px)` }}>
    <div className={'cols-8'}>
      <h1 className={'text-2xl text-center p-2'}>{article.title}</h1>
      <div style={{ all: "initial" }}>
        <div className={'ck ck-editor__main'}>
          <div className={'ck-blurred ck ck-content ck-rounded-corners'} dangerouslySetInnerHTML={{ __html: article.content }}></div>
        </div>
      </div>
    </div>
  </div>
}
