import {Alert, Button, Dialog, DialogBody, DialogFooter, DialogHeader} from "@material-tailwind/react";
import {useEffect, useState} from "react";
import useAlert from "./hook";
import errIcon from '../../assets/chain-err.png'


export default function () {
  const { text, type, setAlert } = useAlert();
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
    queueMicrotask(() => {
      setOpen(!!text)
    })
  }, [text])

  const onConfirm = () => setAlert('')

  return type === 'notify'
    ? <Alert color="blue"
                open={open}
                onClose={() => setOpen(false)}
                className={'fixed z-[999] md:w-1/3 md:ml-[33.33%] w-10/12 ml-[8.333%] top-[20px]'}
                animate={{
        mount: { y: 0 },
        unmount: { y: -30 },
      }}>
        {text}
      </Alert>
    : <Dialog open={open} size={'sm'}>
        <DialogHeader>
          <img src={errIcon} className={'w-12 h-12 mt-8 ml-auto mr-auto'}/>
        </DialogHeader>
        <DialogBody>
          <p>{text}</p>
        </DialogBody>
        <DialogFooter className={'justify-center'}>
          <Button variant="gradient" onClick={onConfirm} className={'ml-2'}>
            <span>确定</span>
          </Button>
        </DialogFooter>
      </Dialog>
}
