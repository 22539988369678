import { createSlice, configureStore } from '@reduxjs/toolkit';

const KEEP_APP = sessionStorage.getItem('KEEP_APP')

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    loginState: ['', 'null'].includes(KEEP_APP) ? null : KEEP_APP,
    signer: null
  },
  reducers: {
    setLogin: (state, action) => {
      sessionStorage.setItem('KEEP_APP', action.payload)
      state.loginState = action.payload;
    },
    setSigner: (state, action) => {
      state.signer = action.payload;
    }
  }
});

export const { setLogin, setSigner } = loginSlice.actions

export default configureStore({
  reducer: {
    login: loginSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

