import {useEffect, useState} from "react";
import {createArticle, getArticle, updateArticle} from "../api";
import {useNavigate, useParams} from "react-router-dom";
import { Input, Button } from "@material-tailwind/react";
import useAlert from '../Components/Alert/hook'
import Editor from '../Components/Editor'

export default function () {
  const { id } = useParams();
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleTitle = e => setTitle(e.target.value);

  const postArticle = async () => {
    const params = { title, content }
    setLoading(true)
    try {
      if (id) {
        await updateArticle(id, params)
      } else {
        await createArticle(params)
      }
      setAlert('已保存！', { type: 'notify' })
      setTimeout(() => {
        navigate('/');
      }, 1500)
    } catch (err) {
      setAlert(err.message, { type: 'dialog' })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      getArticle(id).then(res => {
        setTitle(res.title)
        setContent(res.content)
      })
    }
  }, [])

  return <div>
      <div className="d-flex flex-column justify-content-center">
        <Input label="标题" color="purple" className={"font-bold"} size={"lg"} value={title} onChange={handleTitle} />
        <div className={'mt-5'}></div>
        <Editor value={content} onBlur={val => setContent(val)} />
        <div className={"mt-5 flex justify-center"}>
          <Button color="deep-purple" onClick={postArticle} loading={loading}>保存</Button>
        </div>
      </div>
  </div>
}
