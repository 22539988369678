import {ethers} from "ethers";
import articleJson from './contracts/Article.sol/Article.json'
import { getContract } from './api'

let provider;
let article;
let articleWithSigner;

const getProvider = (wallet) => {
  if (typeof window !== "undefined" && window.ethereum) {
    const { ethereum } = window
    const { providers } = ethereum;

    const supportedProviders = {
      coinbase: providers
        ? providers.find((p) => p.isCoinbaseWallet)
        : ethereum.isCoinbaseWallet ? ethereum : null,
      metamask: providers
        ? providers.find((p) => p.isMetaMask)
        : ethereum.isMetaMask ? ethereum : null
    };

    const selectedProvider = supportedProviders[wallet];
    if (!selectedProvider) {
      throw Error('没有检测到钱包')
    }

    return new ethers.BrowserProvider(selectedProvider);
  } else {
    // Read only provider. Useful for the user end app.
    return ethers.getDefaultProvider({
      chainId: 31337,
    });
  }
};

export const connectWallet = async function (wallet) {
  provider = getProvider(wallet);
  const signer = await provider.getSigner()
  await getContract().then(res => {
    article = new ethers.Contract(res.article_address, articleJson.abi, provider);
    articleWithSigner = article.connect(signer);
  }).catch(err => {
    throw Error(err)
  })
  return signer
}

export {
  provider,
  article,
  articleWithSigner
}
