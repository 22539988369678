import React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import { AlertProvider } from "./Components/Alert/context";
import Alert from './Components/Alert/index';
import store from './store'
import { Provider } from 'react-redux'

const root = createRoot(
  document.getElementById('root')
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <AlertProvider>
      <BrowserRouter>
        <Alert />
        <App />
      </BrowserRouter>
    </AlertProvider>
  </Provider>
// </React.StrictMode>
)
