// import Shadow from './Shadow';
// import root from 'react-shadow';
import {useEffect,useRef} from "react";

function handleSampleError( error ) {
  const issueUrl = 'https://github.com/ckeditor/ckeditor5/issues';

  const message = [
    'Oops, something went wrong!',
    `Please, report the following error on ${ issueUrl } with the build id "moicffi0btba-sj58amh5o99w" and the error stack trace:`
  ].join( '\n' );

  console.error( message );
  console.error( error );
}

export default function Editor ({ value, onBlur }) {
  const editorRef = useRef(null);

  useEffect(() => {
    window.ClassicEditor
      .create(editorRef.current, {
        // Editor configuration
      } )
      .then( editor => {
        editor.ui.view.editable.element.style.minHeight = "300px"; // todo
        editor.editing.view.change( writer => {
          writer.setStyle( 'height', 'calc(100vh - 380px)', editor.editing.view.document.getRoot() )
        })
        editor.editing.view.document.on('blur', () => {
          onBlur(editor.getData())
        })
        window.editor = editor;
        if(value){
          window.editor.setData(value)
        }
      })
      .catch( handleSampleError );
    return () => {
      window.editor && window.editor.destroy()
    }
  }, [])

  useEffect(() => {
    if(value){
      window.editor.setData(value)
    }
  }, [value])

  return <div style={{ all: 'initial' }}>
      <div ref={editorRef}></div>
    </div>
}
