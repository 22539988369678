import { create } from 'kubo-rpc-client'

const client = create(new URL(process.env.REACT_APP_IPFS_URL + '/ipfs/api/v0'))

export const uploadFile = async function (str) {
  const {cid} = await client.add(str)
  return cid.toString()
}

export const getFile = async function (cid) {
  const binary = await client.cat(cid)
  let result = ''

  for await (const file of binary) {
    const uint8Array = new Uint8Array(file);

    const textDecoder = new TextDecoder();
    result += textDecoder.decode(uint8Array);
  }
  return result;
}
