import {useEffect, useState,Suspense} from "react";
import {useNavigate} from 'react-router-dom'
import {getArticles} from '../api'
import day from 'dayjs'
import Loading from "../Components/Loading";
import Empty from "../Components/Empty";
import useAlert from '../Components/Alert/hook'

export default function () {
  const navigate = useNavigate();
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { setAlert } = useAlert()

  const articles = () => {
    getArticles()
      .then(res => setList(res.map(d => ({ ...d, updateTime: day(d.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}))))
      .then(() => setIsLoading(false))
      .catch(err => setAlert(err.message, { type: 'dialog' }))
  }
  useEffect(() => {
    articles()
  }, [])

  return <div className="sm:p-5">
    <Suspense fallback={<Loading />}>
      {
        isLoading
          ? <Loading />
          : list.length ? list.map((d, i) => {
            return <div key={i} className="mb-2 last:mb-0 sm:p-5 p-4 border rounded-md border-[#aeaeae]" onClick={() => navigate(`/detail/${d.id}`)}>
              <h4 className={'text-lg font-bold'}>{ d.title }</h4>
              <div className="mt-2 md:flex justify-between">
                <p className={"truncate pr-2"}>创建人：{ d.author }</p> <p className={'text-nowrap'}>{ d.updateTime }</p>
              </div>
            </div>
          }) : <Empty />
      }
    </Suspense>
  </div>
}
