import { getArticle,getHistory, delArticle } from '../api'
import {useEffect, useState} from "react";
import {useParams, useNavigate} from 'react-router-dom';
import day from 'dayjs'
import {
  Button,
  IconButton,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import useAlert from "../Components/Alert/hook";

export default function () {
  const [article, setArticle] = useState([])
  const [historyData, setHistoryData] = useState([])
  const { id } = useParams();
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const { setAlert } = useAlert();
  const calcTime = (val) => {
    return day(val * 1000).format('YYYY-MM-DD HH:mm:ss')
  };

  useEffect(() => {
    getArticle(id).then(res => setArticle(res))
    getHistory(id).then(res => setHistoryData(res))
  }, [])

  const onHistory = () => setOpen(!open)
  const onDetail = (hash) => {
    window.open(`/history/${id}?hash=${hash}`)
  }
  const onDelete = () => {
    setOpenDel(false)
    delArticle(id).then(() => {
      setAlert("已删除！", { type: 'notify' })
      setTimeout(() => {
        navigate('/');
      }, 1500)
    }).catch(err => {
      setAlert(err.message, { type: 'dialog' })
    })
  };

  return <div>
    <div className={'row'} style={{ minHeight: "calc(100vh - 200px)" }}>
      <div className={'cols-8'}>
        <h1 className={'text-2xl text-center p-2'}>{article.title}</h1>
        <div style={{ all: "initial" }}>
          <div className={'ck ck-editor__main'}>
            <div className={'ck-blurred ck ck-content ck-rounded-corners'} dangerouslySetInnerHTML={{ __html: article.content }}></div>
          </div>
        </div>
      </div>
    </div>
    <div className={"flex flex-col justify-between fixed h-[150px] 2xl:right-20 2xl:bottom-20 xl:right-18 xl:bottom-18 lg:right-8 lg:bottom-15 bottom-10 right-5"}>
      <IconButton variant="outlined" className="rounded-full bg-[#00838f]" onClick={() => navigate(`/edit/${id}`)}>
        <i className={'text-white'}>修改</i>
      </IconButton>
      <IconButton variant="outlined" className="rounded-full bg-[#c62828]" onClick={() => setOpenDel(true)}>
        <i className={'text-white'}>删除</i>
      </IconButton>
      <IconButton variant="outlined" className="rounded-full bg-[#37474f]" onClick={onHistory}>
        <i className={'text-white'}>旧版</i>
      </IconButton>
    </div>
    <Dialog open={openDel} size={'sm'}>
      <DialogHeader>提示</DialogHeader>
      <DialogBody>
        <p>删除后不可恢复，确定？</p>
      </DialogBody>
      <DialogFooter>
        <Button variant="text" onClick={() => setOpenDel(false)}>
          <span>取消</span>
        </Button>
        <Button variant="gradient" onClick={onDelete} className={'ml-2'}>
          <span>确定</span>
        </Button>
      </DialogFooter>
    </Dialog>
    <Dialog open={open} handler={onHistory} size={'lg'}>
      <DialogHeader>历史版本</DialogHeader>
      <DialogBody className={'text-gray-900'}>
        <div className={'divide-y divide-blue-100 divide-solid max-h-[60vh] overflow-auto'}>
        {
          historyData.length ? historyData.map((item, index) => {
            return <div key={index}>
              <div className={"mt-4 mb-4"}>
                <div className={"sm:flex max-w-[340px] justify-between"}>
                  <p>区块高度：{item[0]}</p>
                  <p>创建时间：{calcTime(item[2])}</p>
                </div>
                <p className={'mt-1 flex justify-between'}>
                  <span>版本Hash：</span>
                  <a className="flex-1 underline cursor-pointer truncate inline-block" onClick={() => onDetail(item[1])}>{item[1]}</a></p>
              </div>
            </div>
          }) : <p>No data</p>
        }
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant="gradient" onClick={onHistory} className={"ml-auto mr-auto"}>
          <span>关闭</span>
        </Button>
      </DialogFooter>
    </Dialog>
  </div>
}
