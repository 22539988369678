import MetamaskIcon from '../assets/metamask-icon.svg'
import TestIcon from '../assets/chemistry-flask-icon.svg'
import CoinbaseIcon from '../assets/coinbase.svg'
import {useNavigate} from "react-router-dom";
import { connectWallet } from '../eth'
import useAlert from '../Components/Alert/hook'
import { setLogin, setSigner } from "../store";
import { useDispatch } from 'react-redux'
import QKeep from '../assets/Qkeep.jpeg'
import Warning from '../assets/warning.svg'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@material-tailwind/react";
import {useState} from "react";
import errIcon from "../assets/chain-err.png";

const Cstyle = {
  background: 'rgba(255, 255, 255, 0.16)',
  borderRadius: '16px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(5px)',
  border: '1px solid rgba(255, 255, 255, 0.3)'
}

export default function () {
  const navigate = useNavigate()
  const { setAlert } = useAlert()
  const dispatch = useDispatch()
  const [type, setType] = useState('')
  const [open, setOpen] = useState(false)

  const QStyle = {
    filter: 'brightness(0.4) blur(1px)',
    backgroundImage: `url(${QKeep})`
  }

  const loginType = async (type) => {
    if (type === 'test') {
      dispatch(setLogin(type))
      dispatch(setSigner(null))
      navigate('/')
    } else {
      setType(type)
      setOpen(true)
    }
  }

  const handleOpen = () => setOpen(false)

  const onConfirm = async () => {
    setOpen(false)
    try {
      const signer = await connectWallet(type)

      dispatch(setLogin(type))
      dispatch(setSigner(signer))

      setAlert('connect success!', { type: 'notify' })
      navigate('/')
    } catch (err) {
      setAlert('connect ERROR!' + err.message, { type: 'notify' })
    }
  }

  return <div>
    <div style={QStyle} className={'fixed w-screen h-screen left-0 top-0 z-[-1] sm:bg-cover bg-contain'} />
    <div className={"xxl:w-1/3 lg:w-1/2 sm:w-3/4 ml-auto mr-auto sm:mt-[26vh] mt-[12vh] select-none"}>
      <div className={'flex flex-col sm:flex-row items-center justify-between'}>
        <div onClick={() => loginType('metamask')} style={Cstyle} className={'w-[12rem] h-[12rem] flex flex-col justify-between items-center border rounded-xl p-5 cursor-pointer bg-[#786e6e47] hover:scale-110 transform-gpu duration-100'}>
          <img src={MetamaskIcon} className={'w-28 ml-auto mr-auto'}/>
          <p className={"font-bold"}>Connect Metamask</p>
        </div>
        <div onClick={() => loginType('coinbase')} style={Cstyle} className={'mt-5 sm:mt-0 w-[12rem] h-[12rem] flex flex-col justify-between items-center border rounded-xl p-5 cursor-pointer bg-[#786e6e47] hover:scale-110 transform-gpu duration-100'}>
          <img src={CoinbaseIcon} className={'w-[6.5rem] ml-auto mr-auto'}/>
          <p className={"font-bold"}>Connect Coinbase</p>
        </div>
      </div>
    </div>
    <div onClick={() => loginType('test')} className={'sm:mt-10 mt-5 flex justify-center items-center'}>
      <img src={TestIcon} className={'w-10 mr-2'}/>
      <span className={"font-bold text-[4vmin] relative top-1 underline cursor-pointer"}>No wallet ? Use a test account</span>
    </div>
    <div className={'sm:mt-10 mt-5 mb-20 text-center font-bold text-[4vmin]'} id="login-warning">
      <p>Welcome to Q-keep!</p>
      <p>This DApp is only for personal learning to develop and test web3 based on blockchain tech.</p>
      <p>Please do not connect to real wallet!</p>
    </div>
    <Dialog open={open} handler={handleOpen}>
      <DialogHeader>
        <img src={errIcon} className={'w-12 h-12 mt-8 ml-auto mr-auto'}/>
      </DialogHeader>
      <DialogBody>
        <div>
          <p>First install the Metamask or Coinbase extension on chrome,</p>
          <p className={'break-words'}>Then import the test wallet width token: 59c6995e998f97a5a0044966f0945389dc9e86dae88c7a8412f4603b6b78690d.</p>
          <p>Next add a customized network with an RPC URL of https://dapp.1241.ltd/rpc and Chain ID is 31337 and then switch to this network.</p>

          <div>
            <img className={'inline-block right-6 w-6 mr-2'} src={Warning} />
            <span>There are some problems with Hardhat network and MetaMask about chainId. see: <a className={'underline'} href={'https://hardhat.org/hardhat-network/docs/metamask-issue'} target={'_blank'}>Hardhat doc</a>. Recommended to use coinbase wallet !</span>
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleOpen}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button variant="gradient" color="green" onClick={onConfirm}>
          <span>Confirm</span>
        </Button>
      </DialogFooter>
    </Dialog>
  </div>
}
