import React, {useEffect, useState} from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import Login from './Login'
import PrivateRoute from "./PrivateRoute";
import {useDispatch, useSelector} from 'react-redux';
import {connectWallet} from "./eth";
import {setLogin, setSigner} from "./store";

function App() {
  const navigate = useNavigate();
  const loginState = useSelector(state => state.login.loginState);
  const signer = useSelector(state => state.login.signer);
  const dispatch = useDispatch()
  const [enter, setEnter] = useState(false)

  useEffect(() => {
    if (!loginState) {
      navigate('/login')
    } else if (!signer && loginState !== 'test') {
      connectWallet(loginState)
        .then(signer => dispatch(setSigner(signer)))
        .catch(() => {
          dispatch(setLogin(null))
          setEnter(true)
          navigate('/login')
        })
    }
  }, [])

  useEffect(() => {
    if (loginState === 'test' || (!!loginState && signer)) {
      setEnter(true)
    } else if (!loginState) {
      setEnter(true)
    }
  }, [loginState, signer])

  return (
    enter ?
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<PrivateRoute />} />
    </Routes>
      : null
  );
}

export default App;
