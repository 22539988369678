import React, {useEffect, useState} from 'react';
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom';
import { IconButton, PopoverHandler, Popover, PopoverContent, Button } from "@material-tailwind/react";
import Icon from './assets/icons8-blockchain (1).svg';
import Menu from './assets/menu.svg';
import Home from './Home'
import Edit from './Edit'
import Detail from './Detail'
import History from './History'
import {getBalance, getNetwork} from './api'
import useAlert from './Components/Alert/hook'
import {setLogin} from "./store";
import {useDispatch} from "react-redux";
const { ethers } = require('ethers')

const fStyle = {
  background: `-webkit-linear-gradient(87deg, #ab9b9b, #0052ff 80%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 'bold'
}

export default function () {
  const navigate = useNavigate();
  const location = useLocation();
  const [balance, setBalance] = useState(0)
  const [network, setNetwork] = useState('')
  const { setAlert } = useAlert();
  const dispatch = useDispatch()

  useEffect(() =>{
    getBalance().then(res => setBalance(Number(ethers.formatEther(res.balance)).toFixed(4)))
    getNetwork().then(res => {
      // hardhat localhost network chainId 31337
      if (res.chainId.toString() !== '31337') {
        setAlert('The current network is incorrect, please switch to the specified network type', { type: 'dialog' })
      }
      setNetwork(res.name === 'unknown' ? 'Hardhat localhost' : res.name)
    })
  }, [])

  const logout = () => {
    dispatch(setLogin(null))
    navigate("/login")
  }

  return (
    <div className="xl:w-4/5 lg:w-5/6 md:w-5/6 w-11/12 ml-auto mr-auto">
      <div className="w-full flex items-center mt-5">
        <img onClick={() => navigate('/')} src={Icon} className={'w-16'} style={{ filter: 'drop-shadow(2px 2px 15px #111)' }} />
        <div className="flex-auto" style={fStyle}>
          <p>余额：{balance}GO</p>
          <p>网络：{network}</p>
        </div>
        <div className="flex-4">
          {
            location.pathname === '/' ?
              <>
               <div className={"sm:block hidden"}>
                 <Button onClick={() => navigate("/edit")} color={'deep-purple'}>新建</Button>
                 &ensp;
                 <Button onClick={logout} color={'gray'}>退出</Button>
               </div>
                <Popover placement={'bottom-end'}>
                  <PopoverHandler>
                    <IconButton variant="text" className="rounded-full sm:hidden">
                      <img src={Menu} alt="menu" className={'w-16 h-16'} />
                    </IconButton>
                  </PopoverHandler>
                  <PopoverContent className={'z-[10] w-30 p-0'}>
                    <div>
                      <Button variant="text" onClick={() => navigate("/edit")}>新建</Button>
                    </div>
                    <Button variant="text" onClick={logout}>退出</Button>
                  </PopoverContent>
                </Popover>
              </>
              : null
          }
        </div>
      </div>
      <div className={'relative z-[1] md:p-5 sm:p-3 p-3 sm:rounded-2xl rounded-md mt-5 mb-5 bg-white'} style={{ color: 'initial' }} >
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/detail/:id" element={<Detail />} />
          <Route path="/edit/:id?" element={<Edit />} />
          <Route path="/history/:id?" element={<History />} />
        </Routes>
      </div>
    </div>
  );
}
