import { createContext, useState } from 'react';

const ALERT_TIME = 2000;
const initialState = {
  text: '',
  type: ''
};

const AlertContext = createContext({
  ...initialState,
  setAlert: () => {},
});

let timer = 0

export const AlertProvider = ({ children }) => {
  const [text, setText] = useState('');
  const [type, setType] = useState('notify');

  const setAlert = (text, { type } = {}) => {
    setText(text);
    type && setType(type)

    if (type !== 'dialog') {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        setText('');
      }, ALERT_TIME);
    }
  };

  return (
    <AlertContext.Provider
      value={{
        text,
        type,
        setAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
