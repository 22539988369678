import store from './store'
import { provider, article, articleWithSigner } from './eth'
import {getFile, uploadFile} from "./ipfs";
import { ErrorDecoder } from 'ethers-decode-error'

let signer;

store.subscribe(() => {
  const state = store.getState();
  signer = state.login.signer
});

const BASE_URL = `/api`;
const errorDecoder = ErrorDecoder.create();

async function fetchData(endpoint, params, options = {}) {
  const url = `${BASE_URL}${endpoint}`;
  const isFormData = (params instanceof FormData)

  try {
    const response = await fetch(url, {
      body: isFormData ? params : JSON.stringify(params),
      ...options,
      headers: {
        ...(!isFormData ? { 'Content-Type': 'application/json' } : null),
        ...options.headers
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    if ([201, 204].includes(response.status)) {
      return {}
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}


// 所有接口根据用户登录情况判断是测试钱包登录还是试用账号登录
export async function getArticles() {
  if (signer) {
    const response = await article.getAllArticles()
    return [...response].map(item => {
      return {
        id: item[0].toString(),
        title: item[1],
        author: item[3],
        timestamp: item[4].toString()
      }
    })
  }
  return fetchData('/keep');
}

export async function getArticle(id) {
  if (signer) {
    const result = await article.getArticle(id)
    const [, title, cid, author, timestamp] = [...result]
    const content = (await getFile(cid)).replace(/.*\n/, '')
    return { title, content, author, timestamp: timestamp.toString() }
  }
  return fetchData(`/keep/${id}`);
}

export async function createArticle(params) {
  if (signer) {
    const { title, content } = params
    const cid = await uploadFile(`${title}\n${content}`)
    try {
      const transDetail  =await articleWithSigner.addArticle(title, cid)
      console.log(transDetail)
      return {}
    } catch (err) {
      const decodedError = await errorDecoder.decode(err)
      throw Error(decodedError.reason)
    }
  }

  return fetchData('/keep', params, { method: 'post' });
}

export async function updateArticle(id, params) {
  if (signer) {
    const { title, content } = params
    const cid = await uploadFile(`${title}\n${content}`)
    try {
      const transDetail = await articleWithSigner.updateArticle(id, title, cid)
      console.log(transDetail)
      return {}
    } catch (err) {
      const decodedError = await errorDecoder.decode(err)
      throw Error(decodedError.reason)
    }
  }
  return fetchData(`/keep/${id}`, params, { method: 'put' });
}

export async function getHistory(id, hash) {
  if (signer) {
    if (hash) {
      const result = await article.getHistoryByHash(id, hash)
      const [, , timestamp, , title, cid] = result.map(d => d.toString())
      const content = await getFile(cid)
      return { title, timestamp, content }
    } else {
      const result = await article.getHistories(id);
      return result.map(item => item.map((d => d.toString())))
    }
  }

  const query = hash ? `?hash=${hash}` : ''
  return fetchData(`/keep/history/${id}${query}`)
}

export async function delArticle(id) {
  if (signer) {
    try {
      const transDetail = await articleWithSigner.deleteArticle(id);
      console.log(transDetail)
      return {}
    } catch (err) {
      const decodedError = await errorDecoder.decode(err)
      throw Error(decodedError.reason)
    }
  }

  return fetchData(`/keep/${id}`, {}, { method: 'delete' });
}

export async function getNetwork() {
  if (signer) {
    return await provider.getNetwork()
  }
  return fetchData('/network')
}

export async function getBalance() {
  if (signer) {
    const result = await provider.getBalance(signer)
    return { balance: result.toString() }
  }
  return fetchData('/balance')
}

export async function getContract() {
  return fetchData('/contract')
}
